/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { isJson } from "../CloudAccountList/utils";
import { REQUIRED_FIELD_WARNING } from "../constants";
import {
  IbmFormField,
  GcpFormField,
  AzureFormField,
  AwsFormField,
  ERROR_IS_REQUIRED,
  ERROR_FILE_TYPE,
} from "./constants";

export const awsCredentialsSchema = yup.object().shape({
  [AwsFormField.EXTERNAL_ID]: yup.string().required(REQUIRED_FIELD_WARNING),
  [AwsFormField.ROLE_NAME]: yup.string().required(REQUIRED_FIELD_WARNING),
});

export const azureCredentialsSchema = yup.object().shape({
  [AzureFormField.APP_ID]: yup.string().required(REQUIRED_FIELD_WARNING),
  [AzureFormField.PASSWORD]: yup.string().required(REQUIRED_FIELD_WARNING),
  [AzureFormField.TENANT_ID]: yup.string().required(REQUIRED_FIELD_WARNING),
});

export const gcpCredentialsSchema = yup.object().shape({
  [GcpFormField.KEY]: yup
    .mixed()
    .test("file", ERROR_IS_REQUIRED, (value: FileList) => {
      return hasFiles(value ?? []);
    })
    .test("fileType", ERROR_FILE_TYPE, (value: FileList) => {
      return hasFiles(value ?? []) && isJson(value[0]);
    }),
});

export const ibmCredentialsSchema = yup.object().shape({
  [IbmFormField.API_KEY]: yup.string().required(REQUIRED_FIELD_WARNING),
});

export function hasFiles(list: FileList): boolean {
  return list.length > 0;
}
