/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useServicesQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useServiceOptions() {
  const { data, loading, error } = useServicesQuery();

  const services =
    (data?.services ?? []).filter(isNotNil).map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  return {
    services,
    loading,
    error,
  };
}
