/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getFirstItem } from "~/tools";
import { providersList } from "../constants";
import { ProviderListItem } from "../types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

export function useSelectProvider() {
  const { control, setValue } = useFormContext<ThresholdFormData>();
  const change = useWatch({ control, name: ThresholdFormFields.PROVIDERS });

  const [selectedProvider, setSelectedProvider] = useState<
    ProviderListItem | undefined
  >();

  const onSelect = (provider: ProviderListItem) => {
    setValue(ThresholdFormFields.PROVIDERS, [provider.id]);
  };

  useEffect(() => {
    setSelectedProvider(
      providersList.find(({ id }) => id === getFirstItem(change))
    );
  }, [change]);

  return {
    selectedProvider,
    providersList,
    onSelect,
  };
}
