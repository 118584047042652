/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useEffect } from "react";
import { ThresholdDataSelectItem } from "../types";

export function useHandleListSearch(optionsList: ThresholdDataSelectItem[]) {
  const [searchValue, setSearchValue] = useState("");
  const [searchedList, setSearchedList] =
    useState<ThresholdDataSelectItem[]>(optionsList);

  const onSearchUpdate = (string: string) => {
    setSearchValue(string);
  };

  useEffect(() => {
    setSearchedList(
      optionsList.filter((option) =>
        option.value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  useEffect(() => {
    setSearchedList(optionsList);
    setSearchValue("");
  }, [optionsList]);

  return [searchedList, searchValue, onSearchUpdate] as const;
}
