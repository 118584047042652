/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useCloudServicesQuery } from "~/generated/graphql";
import { getFirstItem, isNotEmpty } from "~/tools";
import { ThresholdDataSelectItem } from "../types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

export function useSelectService() {
  const { control, setValue, trigger } = useFormContext<ThresholdFormData>();
  const change = useWatch({ control, name: ThresholdFormFields.SERVICES });

  const [selectedService, setSelectedService] = useState<
    ThresholdDataSelectItem | undefined
  >();

  const { data: cloudServices, loading, error } = useCloudServicesQuery();

  const services = (cloudServices?.services ?? []).map((service) => ({
    value: service.name,
    id: service.id,
  }));

  const onSelect = (value: ThresholdDataSelectItem) => {
    setValue(ThresholdFormFields.SERVICES, [value.id]);
  };

  useEffect(() => {
    setSelectedService(services.find(({ id }) => id === getFirstItem(change)));

    if (isNotEmpty(change)) {
      trigger(ThresholdFormFields.PROVIDERS);
    }
  }, [change]);

  return {
    services,
    selectedService,
    loading,
    error,
    onSelect,
  };
}
