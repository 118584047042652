/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAwsAccountCredentialsBatchMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useBatchUpdateAwsAccountCredentials() {
  const [updateAwsCredentials, ...mutationState] =
    useUpdateAwsAccountCredentialsBatchMutation();

  const handleAwsCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Aws>,
    selectedNids: string[]
  ) => {
    const { roleName, externalId } = variables;

    await updateAwsCredentials({
      variables: {
        nids: selectedNids,
        input: {
          roles: [{ roleName, externalId }],
        },
      },
    });
  };

  return [handleAwsCredentialsUpdate, mutationState] as const;
}
