/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  ThresholdsDocument,
  ThresholdsQuery,
  ThresholdsQueryVariables,
  useUpdateThresholdMutation,
} from "~/generated/graphql";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isNotNil } from "~/tools";
import { ThresholdFormData } from "../validators";

export function useUpdateThreshold(id: string) {
  const [
    update,
    {
      client: { cache },
      ...mutationState
    },
  ] = useUpdateThresholdMutation();

  const onUpdate = async (data: ThresholdFormData) => {
    const input = mapUpdateInput(data);

    try {
      const response = await update({
        variables: {
          id,
          input,
        },
      });

      if (isNotNil(response.data)) {
        handleCacheUpdate(cache, {
          ...response.data.updateThreshold,
          createdDate: "",
        });
      }

      showSuccess(SUCCESS_TEXT.thresholdUpdated);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return [onUpdate, mutationState] as const;
}

function handleCacheUpdate(
  cache: ApolloCache<unknown>,
  updatedItem: ThresholdsQuery["thresholds"][number]
) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "thresholds",
  });
  cache.updateQuery<ThresholdsQuery, ThresholdsQueryVariables>(
    {
      query: ThresholdsDocument,
    },
    (thresholds) => ({
      ...thresholds,
      thresholds: (thresholds?.thresholds ?? []).map((threshold) =>
        threshold.id === updatedItem.id ? updatedItem : threshold
      ),
    })
  );
  cache.gc();
}

function mapUpdateInput(formData: ThresholdFormData) {
  const {
    externalRecipients,
    granularity,
    name,
    accounts,
    services,
    providers,
    thresholdAmount,
    userRecipients,
  } = formData;

  return {
    externalRecipients,
    granularity,
    name,
    resourceSelector: {
      accounts,
      services,
      providers,
    },
    thresholdAmount: Number(thresholdAmount),
    userRecipients,
  };
}
