import { MultipleSelect, SelectButton } from "@nordcloud/gnui";
import { ThresholdDataFilterTabs } from "../../hooks/useHandleThresholdDataFilterTabs";

type TabSelectorProps = {
  tabs: { name: ThresholdDataFilterTabs; label: string; isActive: boolean }[];
  onTabSelect: (tabName: ThresholdDataFilterTabs) => void;
};

export function TabSelector({ tabs, onTabSelect }: TabSelectorProps) {
  return (
    <MultipleSelect>
      {tabs.map(({ name, label, isActive }) => (
        <SelectButton
          key={name}
          name={name}
          value={name}
          labelText={label}
          isActive={isActive}
          onClick={() => onTabSelect(name)}
        />
      ))}
    </MultipleSelect>
  );
}
