/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isNotNil } from "~/tools";

export function useHandleExternalRecipients(recipients?: string[]) {
  const [recipientSlots, setRecipientSlots] = useState<RecipientSlot[]>([]);

  const onAddRecipientSlot = () => {
    const id = uuidv4();

    setRecipientSlots((currentSlots) => [...currentSlots, { id, value: null }]);
  };

  const onRemoveRecipientSlot = (id: RecipientSlot["id"]) => {
    setRecipientSlots((currentSlots) =>
      currentSlots.filter((slot) => slot.id !== id)
    );
  };

  const onFillRecipientSlot = (id: string, value: string) => {
    const slotSelected = recipientSlots.find((slot) => slot.id === id);

    if (isNotNil(slotSelected)) {
      setRecipientSlots((current) =>
        current.map((item) => (item.id === id ? { id: item.id, value } : item))
      );
    }
  };

  useEffect(() => {
    setRecipientSlots(
      isNotNil(recipients) && recipients.length > 0
        ? recipients.map((recipient) => ({ id: uuidv4(), value: recipient }))
        : [{ id: "", value: null }]
    );
  }, []);

  return [
    recipientSlots,
    { onRemoveRecipientSlot, onAddRecipientSlot, onFillRecipientSlot },
  ] as const;
}

type RecipientSlot = {
  id: string;
  value: string | null;
};
