/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import CopyToClipboard from "react-copy-to-clipboard";
import { useFormContext } from "react-hook-form";
import {
  Box,
  FlexContainer,
  Input,
  Label,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup } from "~/components";
import { showSimpleInfo } from "~/services/toast";
import { StepContainer } from "~/views/cloudAccounts/components/StepContainer";
import { MICROSOFT_AZURE } from "~/views/cloudAccounts/constants";
import { AzureFormFields } from "~/views/cloudAccounts/types";
import { AzureFormData } from "../types";

export function AzureAccountCredentialsInfo() {
  const {
    register,
    formState: { errors },
  } = useFormContext<AzureFormData>();

  const command =
    "az account list --query '[].id' -o tsv | xargs -I {} bash -c 'az account set --subscription {} && az ad sp create-for-rbac --name spn-ibm-mca-prod --role Reader --scopes /subscriptions/{}'";

  return (
    <>
      <Text weight="bold">Account Credentials</Text>
      <StepContainer counter={1}>
        Sign in to the{" "}
        <ExternalLink href={MICROSOFT_AZURE}>Microsoft Azure</ExternalLink>
      </StepContainer>
      <StepContainer counter={2}>
        Run Azure Cloud Shell and execute the following command:
      </StepContainer>
      <Box
        boxStyle="grey"
        mt={theme.spacing.spacing01}
        mb={theme.spacing.spacing03}
      >
        <FlexContainer alignItems="flex-start">
          {command}
          <div>
            <CopyToClipboard
              text={command}
              css={{
                cursor: "pointer",
                "padding-left": theme.spacing.spacing01,
              }}
              onCopy={() => showSimpleInfo("Command copied to clipboard")}
            >
              <SVGIcon name="copy" size="sm" />
            </CopyToClipboard>
          </div>
        </FlexContainer>
      </Box>
      <StepContainer counter={3}>
        The output will include credentials that you should provide below
      </StepContainer>
      <FormGroup error={errors[AzureFormFields.APP_ID]}>
        <Label required htmlFor={AzureFormFields.APP_ID} name="App ID" />
        <Input
          placeholder="e.g. b7cb6335-43b6-45ed-8bcc-5b3a6541ffb2"
          {...register(AzureFormFields.APP_ID)}
        />
      </FormGroup>
      <FormGroup error={errors[AzureFormFields.PASSWORD]}>
        <Label required htmlFor={AzureFormFields.PASSWORD} name="Password" />
        <Input {...register(AzureFormFields.PASSWORD)} type="password" />
      </FormGroup>
      <FormGroup error={errors[AzureFormFields.TENANT]}>
        <Label required htmlFor={AzureFormFields.TENANT} name="Tenant" />
        <Input
          placeholder="e.g. 4rcb6335-43b6-45ed-8bcc-5b3a6541fdd0"
          {...register(AzureFormFields.TENANT)}
        />
      </FormGroup>
    </>
  );
}
