import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Box } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { isNotNil, noop } from "~/tools";
import {
  useSelectProvider,
  useSelectAccount,
  useSelectService,
} from "../../hooks";
import { ThresholdDataFilterTabs } from "../../hooks/useHandleThresholdDataFilterTabs";
import { ThresholdDataSelectItem } from "../../types";
import { ThresholdFormData, ThresholdFormFields } from "../../validators";
import { RadioOptionList } from "../RadioOptionList";

export function ListSwitch({
  selectedTab,
}: {
  selectedTab: ThresholdDataFilterTabs;
}) {
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  const {
    providersList: providerList,
    onSelect: onSelectProvider,
    selectedProvider,
  } = useSelectProvider();

  const {
    selectedAccount,
    accounts,
    onSelect: onSelectAccount,
  } = useSelectAccount();

  const {
    selectedService,
    services,
    onSelect: onSelectService,
  } = useSelectService();

  const handleSelect = (value?: ThresholdDataSelectItem) => {
    if (isNotNil(value)) {
      resetThresholdData();

      return (callback: (value_: ThresholdDataSelectItem) => void) =>
        callback(value);
    }

    return (_: (value_: ThresholdDataSelectItem) => void) => noop();
  };

  const lists: Record<ThresholdDataFilterTabs, RadioListControllerProps> = {
    providers: {
      list: providerList,
      name: ThresholdFormFields.PROVIDERS,
      selectedItem: selectedProvider,
      onSelect: (value) => handleSelect(value)(onSelectProvider),
    },
    accounts: {
      list: accounts,
      name: ThresholdFormFields.ACCOUNTS,
      selectedItem: selectedAccount,
      isSearchable: true,
      onSelect: (value) => handleSelect(value)(onSelectAccount),
    },
    services: {
      list: services,
      name: ThresholdFormFields.SERVICES,
      selectedItem: selectedService,
      isSearchable: true,
      onSelect: (value) => handleSelect(value)(onSelectService),
    },
  };

  const resetThresholdData = () => {
    setValue(ThresholdFormFields.PROVIDERS, []);
    setValue(ThresholdFormFields.ACCOUNTS, []);
    setValue(ThresholdFormFields.SERVICES, []);
  };

  useEffect(() => {
    if (
      isNotNil(selectedAccount) &&
      isNotNil(selectedProvider) &&
      isNotNil(selectedService)
    ) {
      trigger(ThresholdFormFields.PROVIDERS);
    }
  }, [selectedAccount, selectedProvider, selectedService]);

  return (
    <FormGroup error={errors[ThresholdFormFields.PROVIDERS]}>
      <Box boxStyle="lightGrey" pb={0}>
        <RadioListController {...lists[selectedTab]} />
      </Box>
    </FormGroup>
  );
}

type RadioListControllerProps = {
  list: ThresholdDataSelectItem[];
  selectedItem?: ThresholdDataSelectItem;
  isSearchable?: boolean;
  name: ThresholdFormFields;
  onSelect: (value?: ThresholdDataSelectItem) => void;
};

function RadioListController({
  list,
  selectedItem,
  name,
  isSearchable,
  onSelect,
}: RadioListControllerProps) {
  return (
    <Controller
      name={name}
      render={() => (
        <RadioOptionList
          searchable={isSearchable}
          optionSelected={selectedItem}
          optionsList={list}
          onSelect={onSelect}
        />
      )}
    />
  );
}
