/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  ThresholdsDocument,
  ThresholdsQuery,
  ThresholdsQueryVariables,
  useAddThresholdMutation,
} from "~/generated/graphql";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isNotNil } from "~/tools";
import { ThresholdFormData } from "../validators";

export function useAddThreshold() {
  const [
    add,
    {
      client: { cache },
      ...mutationState
    },
  ] = useAddThresholdMutation();

  const onAdd = async (data: ThresholdFormData) => {
    try {
      const response = await add({
        variables: {
          input: { ...data, thresholdAmount: Number(data.thresholdAmount) },
        },
      });

      if (isNotNil(response.data)) {
        handleCacheUpdate(cache, {
          ...response.data.addThreshold,
          createdDate: "",
        });
      }

      showSuccess(SUCCESS_TEXT.thresholdAdded);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return [onAdd, mutationState] as const;
}

function handleCacheUpdate(
  cache: ApolloCache<unknown>,
  newItem: ThresholdsQuery["thresholds"][number]
) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "thresholds",
  });
  cache.updateQuery<ThresholdsQuery, ThresholdsQueryVariables>(
    {
      query: ThresholdsDocument,
    },
    (thresholds) => ({
      ...thresholds,
      thresholds: [...(thresholds?.thresholds ?? []), newItem],
    })
  );
  cache.gc();
}
