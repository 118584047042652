/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, Message, Spacer, Text, theme } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { CloudAccountStatus } from "~/tools";
import { CloudAccountsQueryState, ProviderTab } from "../../types";
import { isKubernetesOrOpenshiftTab, toTitleCase } from "../../utils";

type Props = {
  provider: ProviderTab;
};

export function AccountWarningNotification({ provider }: Props) {
  const { title, body, statusFilters } = getAccountWarningProps(provider);
  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();

  const onShowAccounts = () => {
    updateQueryState({
      ...state,
      status: statusFilters,
    });
  };

  return (
    <>
      <Message
        status="warning"
        image="warning"
        css={{ marginBottom: theme.spacing.spacing04 }}
      >
        <div>
          <Text
            weight="medium"
            color={theme.color.text.warning}
            mb={theme.spacing.spacing00}
          >
            {title}
          </Text>
          <Spacer height={theme.spacing.spacing02} />
          <Text mb="0" color={theme.color.text.text02}>
            {body}
          </Text>
          <Spacer height={theme.spacing.spacing02} />
          <Button size="md" status="warning" onClick={onShowAccounts}>
            Show Accounts
          </Button>
        </div>
      </Message>
    </>
  );
}

function getAccountWarningProps(providerTab: ProviderTab) {
  if (!isKubernetesOrOpenshiftTab(providerTab)) {
    return {
      title: "Add credentials",
      body: `Please note that for one or more of your accounts, the system 
      either has limited access to billing data or no access at all. As a 
      result, the Cloud Resource Metadata information cannot be displayed.`,
      statusFilters: [
        CloudAccountStatus.BillingOnly,
        CloudAccountStatus.NoAccess,
      ],
    };
  }

  return {
    title: `Onboard ${toTitleCase(providerTab.toLowerCase())} Clusters`,
    body: `One or more ${toTitleCase(providerTab.toLowerCase())} clusters 
    are not onboarded, which means that we don't have access to the cloud
    resource metadata.`,
    statusFilters: [CloudAccountStatus.NoAccess],
  };
}
