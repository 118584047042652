/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useReducer, useState } from "react";
import { When } from "react-if";
import { Box, Button, ModalConfirm } from "@nordcloud/gnui";
import {
  useThresholdsQuery,
  useCloudServicesQuery,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import {
  BreadcrumbsBox,
  BrickLoader,
  DataDisplayWrapper,
  NoData,
  Pagination,
  ReactTableV2,
  UniversalWrap,
} from "~/components";
import { useQueryState, useCurrency } from "~/hooks";
import {
  isNil,
  generateActionConfirmText,
  getFirstItem,
  isNotNil,
} from "~/tools";
import { ACTION, initialState, reducer } from "./thresholdActionsReducer";
import { useThresholdSidebar } from "./ThresholdSidebar/hooks";
import { ThresholdSidebar } from "./ThresholdSidebar/ThresholdSidebar";
import { ThresholdsListColumns } from "./ThresholdsListColumns";
import {
  Threshold,
  ThresholdSortField,
  ThresholdSortOrder,
  ThresholdsQueryState,
} from "./types";
import { useThresholdRemove } from "./useThresholdRemove";
import { useThresholdsSort } from "./useThresholdsSort";

export function ThresholdsPage() {
  const { threshold, isOpen, title, mode, openAdd, openEdit, onClose } =
    useThresholdSidebar();

  const { currency } = useCurrency();

  const { state, updateQueryState } = useQueryState<ThresholdsQueryState>();
  const { column, order, limit, page } = state;

  const { defaultSort, onSort } = useThresholdsSort();

  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  const {
    data: services,
    loading: areServicesLoading,
    error: servicesError,
  } = useCloudServicesQuery();

  const {
    data,
    loading: areThresholdsLoading,
    error,
  } = useThresholdsQuery({
    variables: {
      sorting: {
        field: getColumnEnumValue(column ?? "createdDate"),
        order: order ?? ThresholdSortOrder.Desc,
      },
    },
  });

  const {
    data: cloudAccounts,
    loading: areCloudAccountsLoading,
    error: cloudAccountsError,
  } = useCloudAccountsQuery({
    skip: areThresholdsLoading,
    variables: {
      filter: {
        cloudProviderId: data?.thresholds
          .map((thresholdDetails) => getFirstItem(thresholdDetails.accounts))
          .filter(isNotNil),
      },
      limit: 50,
    },
  });

  const isLoading =
    areServicesLoading || areCloudAccountsLoading || areThresholdsLoading;

  useEffect(() => {
    if (!areThresholdsLoading && isNil(column) && isNil(order)) {
      updateQueryState({
        column: "createdDate",
        order: ThresholdSortOrder.Desc,
      });
    }
  }, [areThresholdsLoading, column, order, updateQueryState]);

  useEffect(() => {
    setThresholds(
      data?.thresholds.slice(page * limit, (page + 1) * limit) ?? []
    );
  }, [limit, page, order, data]);

  const [thresholdActionsState, dispatch] = useReducer(reducer, initialState);

  const closeModal = () => {
    dispatch({ type: ACTION.CLOSE_MODAL });
  };

  const { onDelete, onRemoveHandler } = useThresholdRemove(
    dispatch,
    thresholdActionsState,
    closeModal
  );

  const columns = useMemo(
    () =>
      ThresholdsListColumns({
        currency,
        services: services?.services ?? [],
        cloudAccounts,
        onEditHandler: openEdit,
        onRemoveHandler,
      }),
    [currency, onRemoveHandler, openEdit, services, cloudAccounts]
  );

  const thresholdCount = data?.thresholds.length ?? 0;

  return (
    <>
      <BreadcrumbsBox title="Thresholds" labels={BREADCRUMB_LABELS}>
        <Button icon="plus" onClick={openAdd}>
          Add New Threshold
        </Button>
      </BreadcrumbsBox>
      <UniversalWrap
        errorProps={{ error: error || servicesError || cloudAccountsError }}
        loaderProps={{
          loading: isLoading,
          Component: BrickLoader,
        }}
      >
        <DataDisplayWrapper
          hasData={thresholdCount > 0}
          renderNoData={(message) => (
            <Box>
              <NoData message={message} />
            </Box>
          )}
        >
          <Box spacing="spacing04">
            <ReactTableV2
              striped
              sort
              columns={columns}
              data={thresholds || []}
              defaultSort={defaultSort}
              onSort={onSort}
            />
            <When condition={thresholdCount > 0}>
              <Pagination count={thresholdCount} />
            </When>
          </Box>
        </DataDisplayWrapper>
        <ModalConfirm
          isOpen={thresholdActionsState.isModalConfirmOpen}
          confirm={onDelete}
          actionLabel="Delete"
          contentLabel="Delete Threshold"
          onClose={closeModal}
        >
          {generateActionConfirmText(
            thresholdActionsState.tempThresholdData.name ?? ""
          )("delete")()}
        </ModalConfirm>
      </UniversalWrap>
      <When condition={isOpen}>
        <ThresholdSidebar
          threshold={threshold}
          mode={mode}
          sidebarState={{
            title,
            isOpen: true,
            onClick: onClose,
          }}
        />
      </When>
    </>
  );
}

const BREADCRUMB_LABELS = [{ value: "thresholds", label: "Thresholds" }];

function getColumnEnumValue(
  column: "amount" | "createdDate" | "name" | (string & {})
) {
  switch (column) {
    case "name":
      return ThresholdSortField.Name;
    case "createdDate":
      return ThresholdSortField.CreatedDate;
    case "amount":
      return ThresholdSortField.Amount;
    default:
      return ThresholdSortField.CreatedDate;
  }
}
